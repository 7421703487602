import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import Settings from './settings';
import {ApiService} from './services/api-service';

@inject(EventAggregator, Settings, ApiService)
export class Login{
    constructor(ea, settings, api){
        this.ea = ea;
        this.settings = settings;
        this.api = api;
    }
    login(){
        this.api.login("htm", this.password.value).then((res)=>{
            if(res.success){
                this.settings.options.code = this.password.value;
                this.settings.save();
                this.ea.publish('app-nav', {route:"home"});
            }
        });
        //
    }
}